<template>
  <div class="order-list">
    <nav-bar :nav-info='navInfo' ></nav-bar>
        <van-search
          v-model="searchValue"
          placeholder="请输入搜索订单号"
          @search="onSearch"
          show-action
       >
          <template #action> 
             <div @click="screen">筛选</div>
          </template>
        </van-search>
   
    <!-- 筛选条件搜索 -->
    <van-popup v-model="screenShow" round  position="bottom" :style="{ height: '50%' }" >

      <el-form ref="form" :model="secletQuery" class="secletQuery" label-width="90px">
        <!-- <el-form-item label="运单状态"> 
          <el-select v-model="secletQuery.status" size="small" placeholder="请选择运单状态">  
            <el-option label="待调度" value="21"></el-option>
            <el-option label="在途" value="23"></el-option>
            <el-option label="已结束" value="24"></el-option> 
          </el-select>
        </el-form-item> -->
      <el-form-item label="原始订单号">
        <el-col :span="22">
          <el-input v-model="secletQuery.orderNo" placeholder="请输入原始订单号"  size="small"></el-input>
        </el-col>
        
      </el-form-item>
       <el-form-item label="订单号">
        <el-col :span="22">
          <el-input v-model="secletQuery.orderNo" placeholder="请输入订单号" size="small"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="订单时间">
        <el-col :span="10">
          <el-date-picker type="date" placeholder="开始" size="small" v-model="secletQuery.date1" style="width: 100%;"></el-date-picker>
        </el-col>
        <el-col class="line" :span="2">-</el-col>
        <el-col :span="10">
          <el-time-picker placeholder="结束" size="small" v-model="secletQuery.date2" style="width: 100%;"></el-time-picker>
        </el-col>
      </el-form-item>
    </el-form>

    <div class="btn">
       <el-button class="btn_reset" size="small" round type="warning" >重置</el-button>
      <el-button type="primary" size="small" round class="btn_sub">提交</el-button>
    </div>

    </van-popup>

    <!-- 订单列表 -->
    <van-list v-model="loading" class="list" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="list">
        <div class="orderInfo_show" v-for="(item,index) in list" :key="index">
          <p class="orderInfo_show_orderNo">
            <span>订单号：{{item.waybillNo}}</span>
            <span v-if="item.status==21"><van-tag plain  type="danger" >待调度</van-tag></span>
            <span v-if="item.status==22"><van-tag plain color="#f87026">未开始</van-tag></span>
            <span v-if="item.status==23"><van-tag plain type="primary">在途</van-tag></span>
            <span v-if="item.status==24"><van-tag plain type="success">已完成</van-tag></span>
            <span v-if="item.status==25"><van-tag plain type="warning">已取消</van-tag></span>
          </p>
          <div class="orderInfo_show_list">
            <div class="orderInfo_show_list_left" @click.stop="selectList(item,index)">
              <van-radio-group v-model="radio" class="select_radio" >
                <van-radio :name="item.waybillNo" ></van-radio>
              </van-radio-group>
            </div>
            <div class="orderInfo_show_list_rigth">
              <p class="rigth_receiverName">
                <span>司机：{{item.realName}}</span>
                <span>车牌号：{{item.carNo}}</span>
              </p>
              <p class="rigth_receiverName">
                <span>电话：{{item.phone}}</span>
              </p>
            </div>     
          </div>
        </div>
      </div>
    </van-list>
    <!-- 返回顶部 -->
   <van-button type="default" class="backTop" v-show="isTop" @click="backTop" >
      <van-icon name="arrow-up" size="20"/>
    </van-button>
     <!-- 底部 -->
    <div class="bottom">
      <div class="btnDispatch" @click="upLoadBtn">
        上传回执单
      </div>
     <div class="btnDispatch" @click="endWaybill">结束订单</div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'
import NavBar from '../../components/NavBar/NavBar.vue'
export default {
  components: {
    NavBar
  },
  data() {
    return {
      //navbar信息
      navInfo:{
        title:'运单列表',
        left:true,
        rigth:false,
        path:'/home'
      },
      secletQuery: {
        status:'',
        data1:'',
        data2:'',
        orderNo:''
      },
      list: [],
      searchValue:'',
      isTop:false,
      radio:'',
      finished:false,
      loading:false,
      screenShow:false,//筛选条件弹窗
      query:{
        // status:23,
        // status:21,
        pageSize:10,
        pageNum:0,
        total:0
      },
      selectIds:''
    }
  },
  created() {
    // this.getData()
  },
  mounted() {
    window.addEventListener("scroll",this.showBackTop,true)
  },
  methods: {
    //获取运单列表
    async getData() {
      let params = {
        status:this.query.status,
         page:this.query.pageNum
      }
      // 18952062858
      if (params.status==''||params.status==null)delete params.status
      let res = await this.$Api.getWaybillList(params)
      if (res.data.code==200) {
        let dataList = res.data.data[0].list
        console.log(dataList);
        this.loading = false
        this.query.total = res.data.data[0].total
        if (dataList == null || dataList.length ==0) {
          this.finished = true
          return
        }
        this.list = this.list.concat(dataList)
        if (this.list.length >= this.query.total) {
          this.finished = true
        }
      }
    },
    //触发搜索框根据订单号搜索
    async onSearch(value) {
      console.log(value);
      this.$router.push({
        path:'/waybillListdetail',
        query: {
          waybillNo:value
        }
      })
    },
     //回到顶部
    backTop() {
      let osTop = document.documentElement.scrollTop || document.body.scrollTop;
      console.log(osTop);
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      // 
    },
    //显示按钮
    showBackTop() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 400) {
        this.isTop = true
      } else {
        this.isTop = false
      }
    },
    onLoad() {
      console.log(111);
      this.query.pageNum++;
      this.getData();
    },
    selectList(item,index) {
      console.log(item,index);
      this.selectIds = item.waybillNo
    },
    //上传回执单
    upLoadBtn() {
      Toast("暂未开发，敬请期待...")
    },
    //结束运单
    async endWaybill() {
      if (!this.selectIds) {
        Toast("请选择运单")
        return false
      }
      let params = {
        waybillNo:this.selectIds 
      }
      let res = await this.$Api.endWaybillByWaybillNo(params)
      if (res.data) {
        Toast("结束运单成功")
        this.query.pageNum = 0
        this.list = []
        this.getData()
      } else {
        Toast("结束运单失败，请重新操作")
      }
    },
    //筛选搜索
    screen() {
      console.log('筛选');
      this.screenShow = true
    }
  }
}
</script>
<style lang="scss" scoped>
html,
body {
	height: 100%;
}
.order-list {
  height: 100%;
  margin-bottom: 59px;
  
  // .van-list {
  //   height: 80%;
  //   overflow: auto;

  // }
// 返回顶部
 .backTop {
      position: fixed;
      bottom: 70px;
      right: 20px;
      // border: 1px solid pink;
      width: 20px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .orderInfo_show {
    padding:5px 15px;
    border-bottom: 1px solid #e4e1e1;
    .orderInfo_show_orderNo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 14px;
        
      }
      span:nth-child(1) {
        flex: 5;
        text-align: left;
      }
      span:nth-child(2) {
        flex: 1;
        text-align: right;
      }
    }
    .orderInfo_show_list{
      font-size: 14px;
      display: flex;
      align-items: center;
      .orderInfo_show_list_left {
        width: 30px;
      }
      .orderInfo_show_list_rigth {
        flex: 1;
        color: #bbb9b9;
        .rigth_receiverName {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            flex: 1;
            padding: 5px 0;
          }
        }
      }
    }
  }

}
.bottom {
  z-index: 999;
  height: 49px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  // background-color: #f58374;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  .btnDispatch {
    flex: 1;
   text-align: center;
   line-height: 49px;
   font-size: 18px;
   color: #FFFFFF;
    &:nth-child(1) { 
      background-color: #f6be35; 
     } 
    &:nth-child(2) { 
      background-color: #f87026; 
    } 
  }
 
  
}
 .secletQuery {
   padding: 20px;
   width: 80%;
   margin: 0 auto;
  /deep/ .el-form-item {
      padding: 5px 0;
      margin-bottom: 0!important;
      .el-input__inner {
        font-size: 12px;
      }
      .el-input {
        width: 240px;
      }
    }
    
}
.btn {
  height: 28px;
  width: 60%;
  padding-top: 30px;
  margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
       .btn_reset {
         height: 100%;
         width: 90px;
         padding:0 10px
       }
        .btn_sub {
          height: 100%;
          width: 90px;
         padding:0 10px
       }
    }
</style>